//common property
@mixin heading {
  font-family: $primary-font-stack;
  font-style: normal;
  font-weight: 400;
  color: $dark-text-primary;
  &--medium {
    font-weight: 500;
  }
  &--semibold {
    font-weight: 600;
  }
  &--bold {
    font-weight: 700;
  }
}

//heading
.h1-extra-large {
  @include heading;
  @include font-size(52px, 62px, 72px);
  @include line-height(60px, 70px, 80px);
  @include letter-spacing(-2px, -2.4px, -2.88px);
  & a {
    @include font-size(52px, 62px, 72px);
    @include line-height(60px, 70px, 80px);
    @include letter-spacing(-2px, -2.4px, -2.88px);
    @include on-event {
      text-decoration: none;
    }
  }
}

.h1-large {
  @include heading;
  @include font-size(42px, 52px, 60px);
  @include line-height(52px, 62px, 72px);
  @include letter-spacing(-1.8px, -2px, -2.4px);
  & a {
    @include font-size(42px, 52px, 60px);
    @include line-height(52px, 62px, 72px);
    @include letter-spacing(-1.8px, -2px, -2.4px);
    @include on-event {
      text-decoration: none;
    }
  }
}

h1,
.h1 {
  @include heading;
  @include font-size(38px, 42px, 48px);
  @include line-height(45px, 52px, 60px);
  @include letter-spacing(-1px, -1.52px, -1.92px);
  & a {
    @include font-size(38px, 42px, 48px);
    @include line-height(45px, 52px, 60px);
    @include letter-spacing(-1px, -1.52px, -1.92px);
    @include on-event {
      text-decoration: none;
    }
  }
}

h2,
.h2 {
  @include heading;
  @include font-size(28px, 32px, 39px);
  @include line-height(36px, 40px, 47px);
  @include letter-spacing(-1px, -1.35px, -1.56px);
  & a {
    @include font-size(28px, 32px, 39px);
    @include line-height(36px, 40px, 47px);
    @include letter-spacing(-1px, -1.35px, -1.56px);
    @include on-event {
      text-decoration: none;
    }
  }
}

h3,
.h3 {
  @include heading;
  @include font-size(28px, 32px, 39px);
  @include line-height(36px, 40px, 47px);
  @include letter-spacing(-1px, -1.35px, -1.56px);
  & a {
    @include font-size(28px, 32px, 39px);
    @include line-height(36px, 40px, 47px);
    @include letter-spacing(-1px, -1.35px, -1.56px);
    @include on-event {
      text-decoration: none;
    }
  }
}

h4,
.h4 {
  @include heading;
  @include font-size(18px, 24px, 28px);
  @include line-height(26px, 30px, 34px);
  @include letter-spacing(-0.7px, -1, -1.12px);
  & a {
    @include font-size(18px, 24px, 28px);
    @include line-height(26px, 30px, 34px);
    @include letter-spacing(-0.7px, -1, -1.12px);
    @include on-event {
      text-decoration: none;
    }
  }
}

h5,
.h5 {
  @include heading;
  @include font-size(16px, 19px, 23px);
  @include line-height(22px, 24px, 28px);
  @include letter-spacing(-0.7px, -0.8px, -0.92px);
  & a {
    @include font-size(16px, 19px, 23px);
    @include line-height(22px, 24px, 28px);
    @include letter-spacing(-0.7px, -0.8px, -0.92px);
    @include on-event {
      text-decoration: none;
    }
  }
}

h6,
.h6 {
  @include heading;
  @include font-size(15px, 17px, 19px);
  @include line-height(18px, 20px, 23px);
  @include letter-spacing(-0.38px, -0.38px, -0.38px);
  & a {
    @include font-size(15px, 17px, 19px);
    @include line-height(18px, 20px, 23px);
    @include letter-spacing(-0.38px, -0.38px, -0.38px);
    @include on-event {
      text-decoration: none;
    }
  }
}

//lead, a, li, label
.lead {
  @include heading;
  @include font-size(16px, 18px, 20px);
  @include line-height(23px, 25px, 30px);
  & a {
    @include font-size(16px, 18px, 20px);
    @include line-height(23px, 25px, 30px);
    @include on-event {
      text-decoration: none;
    }
  }
}

a {
  font-family: $primary-font-stack;
  font-style: normal;
  font-weight: 400;
  @include font-size(14px, 14px, 14px);
  @include line-height(20px, 20px, 20px);
  @include on-event {
    text-decoration: none;
  }
}

li,
label {
  @include heading;
  @include font-size(14px, 14px, 14px);
  @include line-height(20px, 20px, 20px);
  @include on-event {
    text-decoration: none;
  }
}

//paragraph
p,
.p-reg {
  @include heading;
  @include font-size(14px, 14px, 14px);
  @include line-height(20px, 20px, 20px);
  & a {
    @include font-size(14px, 14px, 14px);
    @include line-height(20px, 20px, 20px);
    @include on-event {
      text-decoration: none;
    }
  }
}

.p-mid {
  @include heading;
  @include font-size(14px, 14px, 16px);
  @include line-height(20px, 20px, 24px);
  & a {
    @include font-size(14px, 14px, 16px);
    @include line-height(20px, 20px, 24px);
    @include on-event {
      text-decoration: none;
    }
  }
}

.p-large {
  @include heading;
  @include font-size(14px, 16px, 18px);
  @include line-height(20px, 26px, 28px);
  & a {
    @include font-size(14px, 16px, 18px);
    @include line-height(20px, 26px, 28px);
    @include on-event {
      text-decoration: none;
    }
  }
}
