.toolbar-dropdown-container {
  display: flex;
  align-items: center;
  gap: 12px;
  background: white;
  height: 32px;
}

html {
  overflow-y: auto;
}
