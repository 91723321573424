.pressure-profile-wellbore-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.pressure-profile-wellbore-error-message {
  display: flex;
  font-size: 14px;
  padding: 10px;
}

.pressure-profile-wellbore-inside-container {
  height: 300px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.pressure-profile-wellbore-side-shapes-left {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}

.pressure-profile-wellbore-side-shapes-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.pressure-profile-wellbore-bore {
  width: 45px;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.pressure-profile-wellbore-center {
  position: relative;
  width: 17px;
  height: 100%;
  background-color: #e7ecee;
}

.pressure-profile-wellbore-tubing {
  position: absolute;
  top: 0;
  width: 100%;
  border-right: #323f46;
  border-left: #323f46;
  border-right-width: 2px;
  border-left-width: 2px;
  border-right-style: solid;
  border-left-style: solid;
}

.pressure-profile-wellbore-left {
  position: relative;
  width: 14px;
  height: 100%;
  border-left: #323f46;
  border-left-width: 2px;
  border-left-style: solid;
}

.pressure-profile-wellbore-right {
  position: relative;
  width: 14px;
  height: 100%;
  border-right: #323f46;
  border-right-width: 2px;
  border-right-style: solid;
}

.pressure-profile-wellbore-fill {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #c3e7f1;
}

.pressure-profile-wellbore-packer-container {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.pressure-profile-wellbore-packer {
  position: relative;
  border: #323f46;
  border-width: 1px;
  border-style: solid;
  width: 14px;
  height: 14px;
}

.pressure-profile-wellbore-valve {
  position: absolute;
  width: 8px;
  height: 2px;
}

.pressure-profile-wellbore-arrow-container {
  display: 'flex';
  position: absolute;
  bottom: 0;
  margin-bottom: 8px;
  margin-left: 1px;
  height: 0px;
  z-index: 1;
}
