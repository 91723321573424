.card-viewer-data {
  color: #323f46;
  font-family: Mulish;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: var(--font-weight-medium);
  line-height: 1.125rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
}
.card-viewer-title {
  text-align: left;
  padding-left: 0.25rem;
}
.card-viewer-value {
  text-align: right;
  padding-right: 0.25rem;
}
