.card-header {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.card-header-content {
  display: flex;
  padding: 20px 24px 0px 24px;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}

.card-header-frame {
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
}

.card-header-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.card-header-text {
  color: var(--neutral-grey-800, #191f23);
  font-size: 18px;
  font-style: normal;
  font-weight: var(--font-weight-bolder);
  line-height: 28px;
}

.card-header-divider {
  display: flex;
  height: 1px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  background: var(--neutral-grey-100, #d0d8dd);
  margin: 12px 0px;
}

.card-header-icon {
  display: flex;
  stroke: var(--primary-indigo-700, #005f7c);
  padding: 5px 0;

  svg {
    height: 20px !important;
    width: 20px !important;
  }

  &:hover {
    background-color: var(--dark-mode-800, #273446) !important;
  }
}

.card-header-icon:hover {
  cursor: pointer;
}

.widget-header-text {
  color: var(--Primary-Indigo-700, #005f7c);
  font-size: 18px;
  font-weight: var(--font-weight-bolder);
  line-height: 28px;
}