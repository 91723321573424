.asset-status-card {
  margin: 0;
}

.asset-status-outer-container-padding {
  padding: 20px 32px 20px 32px;
  height: auto;
}

.asset-status-table-border {
  border: 1px solid;
  border-radius: 8px;
  overflow: hidden;
}

.asset-status-container-title-text {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
}

.asset-status-key-value-text {
  span {
    font-size: 14px;
    font-weight: var(--font-weight-medium);
  }
}

.asset-status-refresh-icon {
  cursor: pointer;
}

.table-diagram-single-value-container {
  border: 1px solid;
  justify-content: space-between;
  border-radius: 8px;
  display: flex;
  width: 100%;
  height: 44px;
  align-items: center;
  padding: 0px 16px 0px 16px;
}

.table-diagram-multi-value-container {
  border: 1px solid;
  justify-content: space-between;
  border-radius: 8px;

  .bottom-divider {
    border-bottom: 1px solid;
  }
}

.facility-table-container {
  border-radius: 8px;
  padding-bottom: 16px;
}

.facility-table-header {
  text-align: left;
}

.facility-table-bottom-border {
  border-bottom: 1px solid;
}

.facility-table-accordion-container {
  display: flex;
  position: relative;
  flex-direction: column;
}

.carrot {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
}

.facility-table-parent-rows {
  max-height: 48px;
  align-items: center;
  display: flex;
}

.facility-inner-table-container {
  border: 1px solid;
  border-radius: 8px;
}

.facility-basis-sevenths {
  width: 14.2857142857%;
  display: flex;
  padding: 0px 12px 0px 12px;
  word-break: break-word;

  span {
    font-size: 12px;
  }
}

.facility-display-state-good {
  border: 1px solid;
  border-radius: 4px;
  padding: 2px 8px 2px 8px;
}

.facility-display-state-error {
  border: 1px solid;
  border-radius: 4px;
  padding: 2px 8px 2px 8px;
}

.facility-display-state-warning {
  border: 1px solid;
  border-radius: 4px;
  padding: 2px 8px 2px 8px;
}

.facility-display-state-normal {
  border: 1px solid;
  border-radius: 4px;
  padding: 2px 8px 2px 8px;
}

.facility-text-fourths {
  flex-basis: 25%;
  display: flex;
  padding: 0px 12px 0px 12px;

  span {
    font-size: 12px;
  }
}

.asset-status-basis-seconds {
  flex-basis: 50%;
  display: flex;
  padding: 0px 12px 0px 12px;
  font-size: 14px;
}

.asset-status-basis-thirds {
  flex-basis: 33.3333333333%;
  display: flex;
  padding: 0px 12px 0px 12px;
  font-size: 14px;
  font-weight: var(--font-weight-medium);

  span {
    font-size: 12px;
    font-weight: var(--font-weight-bolder);
  }
}

.asset-status-no-rows {
  font-size: 14px;
  font-weight: var(--font-weight-medium);
}
