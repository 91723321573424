.btn {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  color: $neutral-dark-shade1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 8px 14px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  border-radius: 8px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-primary {
  border: 1px solid $light-primary-shade3;
  background: $light-primary-shade2;
  color: $dark-text-primary;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  &:hover {
    color: $dark-text-primary;
    background-color: $light-primary-shade3;
    border-color: $light-primary-shade2;
  }

  &:focus {
    color: $dark-text-primary;
    background-color: $light-primary-shade3;
    border-color: $light-primary-shade2;
  }

  &:active {
    color: $dark-text-primary;
    background-color: $light-primary-shade3;
    border-color: $light-primary-shade2;
  }
}

.btn-default {
  border: 1px solid $neutral-light-shade4;
  background: $white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  &:hover {
    color: $dark-text-primary;
    background-color: $light-primary-shade3;
    border-color: $light-primary-shade2;
  }

  &:focus {
    color: $dark-text-primary;
    background-color: $light-primary-shade3;
    border-color: $light-primary-shade2;
  }

  &:active {
    color: $dark-text-primary;
    background-color: $light-primary-shade3;
    border-color: $light-primary-shade2;
  }
}

.btn-secondary {
  color: $light-primary-shade3;
  border-radius: 8px;
  border: 1px solid $neutral-light-shade3;
  background: $white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  &:hover {
    color: $light-primary-shade3;
    border-radius: 8px;
    border: 1px solid $neutral-light-shade3;
    background: $white;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  &:focus {
    color: $light-primary-shade3;
    border-radius: 8px;
    border: 1px solid $neutral-light-shade3;
    background: $white;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}

.btn-orange {
  color: $light-secondary-shade1;
  border-radius: 4px;
  background: $light-secondary-shade9;
  border: 0;
  padding: 4px 12px 4px 10px;

  &:hover {
    color: $light-secondary-shade1;
    border-radius: 4px;
    background: $light-secondary-shade9;
    border: 0;
    padding: 4px 12px 4px 10px;
  }

  &:focus {
    color: $light-secondary-shade1;
    border-radius: 4px;
    background: $light-secondary-shade9;
    border: 0;
    padding: 4px 12px 4px 10px;
  }
}

.btn {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  color: $neutral-dark-shade1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 8px 14px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  border-radius: 8px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-primary {
  border: 1px solid $light-primary-shade3;
  background: $light-primary-shade2;
  color: $dark-text-primary;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  &:hover {
    color: $dark-text-primary;
    background-color: $light-primary-shade3;
    border-color: $light-primary-shade2;
  }

  &:focus {
    color: $dark-text-primary;
    background-color: $light-primary-shade3;
    border-color: $light-primary-shade2;
  }

  &:active {
    color: $dark-text-primary;
    background-color: $light-primary-shade3;
    border-color: $light-primary-shade2;
  }
}

.btn-default {
  border: 1px solid $neutral-light-shade4;
  background: $white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  &:hover {
    color: $dark-text-primary;
    background-color: $light-primary-shade3;
    border-color: $light-primary-shade2;
  }

  &:focus {
    color: $dark-text-primary;
    background-color: $light-primary-shade3;
    border-color: $light-primary-shade2;
  }

  &:active {
    color: $dark-text-primary;
    background-color: $light-primary-shade3;
    border-color: $light-primary-shade2;
  }
}

.btn-secondary {
  color: $light-primary-shade3;
  border-radius: 8px;
  border: 1px solid $neutral-light-shade3;
  background: $white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  &:hover {
    color: $light-primary-shade3;
    border-radius: 8px;
    border: 1px solid $neutral-light-shade3;
    background: $white;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  &:focus {
    color: $light-primary-shade3;
    border-radius: 8px;
    border: 1px solid $neutral-light-shade3;
    background: $white;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}

.btn-orange {
  color: $light-secondary-shade1;
  border-radius: 4px;
  background: $light-secondary-shade9;
  border: 0;
  padding: 4px 12px 4px 10px;

  &:hover {
    color: $light-secondary-shade1;
    border-radius: 4px;
    background: $light-secondary-shade9;
    border: 0;
    padding: 4px 12px 4px 10px;
  }

  &:focus {
    color: $light-secondary-shade1;
    border-radius: 4px;
    background: $light-secondary-shade9;
    border: 0;
    padding: 4px 12px 4px 10px;
  }
}

.btn-dark-orange {
  border-radius: 8px;
  border: 1px solid var(--error-red-600, #d92d20);
  background: var(--error-red-600, #d92d20);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  &:hover {
    border-radius: 8px;
    border: 1px solid var(--error-red-600, #d92d20);
    background: var(--error-red-600, #d92d20);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  &:focus {
    border-radius: 8px;
    border: 1px solid var(--error-red-600, #d92d20);
    background: var(--error-red-600, #d92d20);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}