@import '../../../styles/global.scss';

$tooltip-text-color: white;
$tooltip-background-color: #003747;

.tooltip-wrapper {
  display: inline-block;
  position: relative;

  button.ui-icon-button-toolbar-button {
    svg {
      max-height: 18px;
    }

    svg path {
      stroke: #fff;
    }

    svg circle,
    svg ellipse {
      fill: #fff;
    }
  }

  button.ui-icon-button-toolbar-button.ui-icon-button-toolbar-button-active {
    svg path {
      stroke: #60bfda;
    }

    svg circle,
    svg ellipse {
      fill: #60bfda;
    }
  }
}

.tooltip-tip {
  @include captionBold;

  position: absolute;
  border-radius: 4px;
  left: 10%;
  transform: translateX(-50%);
  padding: 8px 12px;
  color: $tooltip-text-color;
  background: $tooltip-background-color;
  z-index: 100;
  width: 200px;


}

.ui-icon-button-toolbar,
.content-header-frame,
.options.ui,
.card-viewer-body {
  .tooltip-wrapper {
    .tooltip-tip.ui {
      width: max-content;
    }
  }
}


.tooltip-tip::before {
  content: ' ';
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 6px;
  margin-left: calc(6px * -1);
}

.tooltip-tip.top {
  top: -38px;
  left: 50%;
}

.tooltip-tip.top::before {
  top: 100%;
  border-top-color: $tooltip-background-color;
}

.tooltip-tip.right {
  left: 145%;
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.tooltip-tip.right::before {
  left: calc(6px * -0.9);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: $tooltip-background-color;
}

.tooltip-tip.bottom {
  bottom: calc(30px * -1);
}

.tooltip-tip.bottom::before {
  bottom: 100%;
  border-bottom-color: $tooltip-background-color;
}

.tooltip-tip.left {
  left: auto;
  right: calc(50% + 30px);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  max-width: 100px;
}

.tooltip-tip.left::before {
  left: auto;
  right: calc(6px * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: $tooltip-background-color;
}