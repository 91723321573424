@import url('/src/features/rod-lift-analysis/RodLiftAnalysis/RodLiftAnalysis.css');
.scrollable-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.performance-curve-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-grey-50, #e7ecee);
  background: var(--shades-white, #fff);

  /* Shadows/sm */
  box-shadow:
    0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.performance-curve-content {
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.performance-curve-graph {
  display: flex;
  width: 98%;
  padding: 12px 0px;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  flex-direction: column;
}

.vertical-divider {
  background: var(--neutral-grey-100, #d0d8dd);
  align-self: stretch;
  width: 1px;
}

.header {
  display: flex;
  padding: 0px 24px 20px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;
  align-self: stretch;
}

.content-header-frame {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.gl-analysis-card {
  display: flex;
  height: 546px;
  padding: 0px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.curve-graph {
  width: 100%;
}
.data-frame {
  display: flex;
  padding: 8px 0px 20px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
}

.tabs {
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.performance-data-frame {
  display: flex;
  height: 365px;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-grey-50, #e7ecee);
  background: var(--shades-white, #fff);

  /* Shadows/sm */
  box-shadow:
    0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.gl-analysis-performance-data-header {
  padding: 10px 0px 0px 11px;
  display: flex;
  align-items: center;
  gap: 0.9375rem;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  border: 1px solid #d0d8dd;
  background: #fff;
  width: 100%;
}

.gl-liftanalysis-body {
  display: flex;
  height: 16rem;
  border: 1px solid #d0d8dd;
  background: #f7f9f9;
  width: 100%;
}

.gl-liftanalysis-scroll-area {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.gl-container {
  width: 47em;
  overflow-x: auto;
  white-space: nowrap;
}

.data-container {
  display: flex;
  padding: 20px 20px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.container1 {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.input-container {
  display: flex;
  height: 356px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}

.output-container {
  display: flex;
  height: 356px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}

.analysis-result-item-header-container {
  width: 100%;
  border-radius: 0.5rem 0.5rem 0 0;
}
.card-date-item-container {
  width: 100%;
}
.tab-content {
  width: 100%;
}

.gl-analysis-chart-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.gl-analysis-pressure-profile-well-and-chart {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.gl-analysis-pressure-profile-wellbore {
  display: flex;
  width: 122.5px;
  height: 100%;
}

.gl-analysis-pressure-profile-chart {
  display: flex;
  width: 100%;
  height: 100%;
}

.gl-analysis-performance-curve-chart {
  display: flex;
  width: 100%;
  height: 100%;
}
