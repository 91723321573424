.ui-button-toolbar {
  display: flex;
  flex-direction: row;
  padding-top: 1em;
  gap: 8px;
  padding-left: 32px;
}

.ui-button-toolbar-button {
  border: none;
  color: #4b5e68;
  background-color: transparent;
  padding: 0px 4px 10px 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: var(--font-weight-medium);
  line-height: 19px; /* 118.75% */
}

.ui-button-toolbar-button:hover {
  cursor: pointer;
  color: #191f23;
}

.ui-button-toolbar-button-active {
  border-bottom: 2px solid;
  border-bottom-color: #dc4405;
  color: #005f7c;
}
