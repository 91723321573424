.timeline-handle-line {
  position: relative;
  width: 1px;
  height: 9px;
  background-color: #e0e0e0;
  z-index: 1;
}

.timeline-container {
  user-select: none;
  display: flex;
  height: 56px;
  width: 100%;
  padding-left: 10px;
  padding-right: 11px;
}

.timeline-border-container {
  border: 1px solid #b8c5cc;
}

.timeline-inner-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.timeline-card-viewer-container-border {
  border: 1px solid;
  border-radius: 4px;
}

.timeline-date-range-box {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: rgba(226, 240, 243, 0.656);
  border: 1px dashed #00789e;
}

.timeline-handle-left {
  display: flex;
  position: absolute;
  left: -10px;
  bottom: 19px;
  width: 10px;
  cursor: ew-resize;
  height: 16px;
  background-color: #00789e;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 1px;
  padding-right: 1px;
  z-index: 2;
}

.timeline-handle-right {
  display: flex;
  position: absolute;
  right: -10px;
  bottom: 19px;
  width: 10px;
  cursor: ew-resize;
  height: 16px;
  background-color: #00789e;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 1px;
  padding-right: 1px;
  z-index: 2;
}

.timeline-date-line-container {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  margin-right: 1px;
}

.timeline-tick {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
}

.timeline-tick-line {
  width: 1px;
  height: 100%;
  background-color: #d0d8dd;
  z-index: 1;
  pointer-events: none;
}

.timeline-tick-label {
  margin-left: 5px;
  margin-top: 5px;
  font-size: 12px;
  color: #191f23;
  z-index: 1;
  pointer-events: none;
}

.timeline-outer-container {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-top: 16px;
  z-index: 0;
}

.timeline-tooltip-container {
  position: absolute;
  justify-content: space-between;
  display: flex;
  height: 16px;
  z-index: 3;
  pointer-events: none;
  top: -16px;
}

.timeline-tooltip {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #003747;
  border-radius: 5px;
  padding-left: 2px;
  padding-right: 2px;
  color: white;
  z-index: 3;
  pointer-events: none;
  transition: opacity 0.3s;
  font-size: 10px;
  font-weight: var(--font-weight-medium);
  white-space: nowrap;
}

.timeline-tick-line-tooltip-container {
  display: flex;
  position: absolute;
  bottom: 30px;
  z-index: 3;
  pointer-events: none;
}

.timeline-group-container {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
}

.timeline-group-start-tick,
.timeline-group-end-tick {
  width: 2px;
  height: 20px;
  background-color: #d0d8dd;
  z-index: 1;
}

.timeline-group-line {
  flex-grow: 1;
  height: 2px;
  background-color: #d0d8dd;
  z-index: 1;
}

.timeline-group-tooltip {
  position: absolute;
  translate: -50%;
  bottom: 100%;
  z-index: 1;
}

.timeline-group-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #003747 transparent transparent transparent;
}

.timeline-reset-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  background-color: #00789e;
  border-radius: 50%;
  pointer-events: all;
  cursor: pointer;
  z-index: 2;
}

.timeline-reset-button-line {
  position: absolute;
  top: 50%;
  width: 8px;
  height: 1px;
  background-color: white;
  z-index: 2;
}

.timeline-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
}

.timeline-icon-container.linked {
  background-color: #253040;
}

.timeline-scrollbar-container {
  display: flex;
  width: 100%;
  height: 20px;
  justify-content: space-between;
}

.timeline-scrollbar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}

.timeline-scrollbar {
  display: flex;
  width: 100%;
  align-items: flex-start;
}

.timeline-scrollbar-inner {
  display: flex;
  position: relative;
  height: 100%;
  border-radius: 8px;
  background-color: #d0d8dd;
}
