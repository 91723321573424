.ui-icon-button-toolbar {
  font-size: 0.875rem;
  font-style: normal;
  line-height: 1.25rem;
  display: flex;
  width: 4.25rem;
  height: 2rem;
  padding: 0.125rem 0.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  background: #e7ecee;
}

.ui-icon-button-toolbar-button {
  display: flex;
  width: 1.875rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  border-radius: 0.125rem;
  border: none;

  box-shadow:
    0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.ui-icon-button-toolbar-background-color {
  background-color: var(--shades-white, #fff);
}

.ui-icon-button-toolbar-button:hover {
  cursor: pointer;
  color: #191f23;
}

.ui-icon-button-toolbar-icon {
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  color: #a0b1bb;
}

.ui-icon-button-toolbar-icon-active {
  color: #00789e;
  filter: brightness(0) saturate(100%) invert(23%) sepia(83%) saturate(2496%) hue-rotate(176deg) brightness(96%)
    contrast(101%);
}
