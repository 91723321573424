.trend-label-item-data {
  padding: 5px;
  display: flex;
  align-items: center;
  align-self: stretch;
}

.trend-label-item-title {
  color: var(--Neutral-Grey-800, var(--Neutral-Grey800, #191f23));
  font-weight: var(--font-weight-medium);
  flex: 1 0 0;
}

.trend-label-item-color {
  padding-left: 5px;
  height: 20px;
}

.trend-label-item-value {
  color: var(--Neutral-Grey-800, var(--Neutral-Grey800, #191f23));
}
