.group-status-alarms-card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
}

.group-status-alarms-percentage {
  color: var(--Error-Red-600, var(--Error-Red600, #d92d20));
  text-align: right;
  font-size: 48px;
  font-weight: var(--font-weight-bolder);
  line-height: 60px;
  letter-spacing: -1.92px;
}

.group-status-alarms-details {
  color: var(--Neutral-Grey-800, var(--Neutral-Grey800, #191f23));
  text-align: right;
  font-size: 18px;
  font-weight: var(--font-weight-medium);
  line-height: 28px;
}
