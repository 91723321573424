.rodliftanalysis-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.rodliftanalysis-container.ui {
  margin-top: 1.5rem;
}

.rodliftanalysis-container.ui .analysis-result-item-header-container {
  border-radius: 0.5rem 0.5rem 0 0;
}

.rodliftanalysis-container.ui .analysis-input-output-body-container {
  border-radius: 0 0 0.5rem 0.5rem;
}

.rodliftanalysis-container.ui .analysis-input-output-body-container .card-viewer-data {
  padding: 0.75rem 0.5rem;
}

.rodliftanalysis-container.ui .analysis-result-button {
  border: 0;
  box-shadow: none;
}

.rodliftanalysis-container.ui .analysis-result-item-header-text {
  font-size: 14px;
}

.rodliftanalysis-container.ui .card-viewer-title,
.rodliftanalysis-container.ui .card-viewer-value,
.rodliftanalysis-container.ui .xd-analysis-textarea {
  font-size: 12px;
}

.rodliftanalysis-container.ui .rodliftanalysis-scroll-area {
  overflow: hidden;
}

.theme-dark .rodliftanalysis-container.ui .rodliftanalysis-scroll-area {
  background-color: #001023;
}

.rodliftanalysis-header {
  display: flex;
  align-items: center;
  gap: 0.9375rem;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  border: 1px solid #d0d8dd;
  background: #fff;
  width: 100%;
}

.rodliftanalysis-header-collapsed {
  border-radius: 0.5rem;
}

.rodliftanalysis-body {
  display: flex;
  height: 20rem;
  border: 1px solid #d0d8dd;
  background: #f7f9f9;
  width: 100%;
}
.espanalysis-body-with-timeline {
  height: 16rem;
}
.rodliftanalysis-body-with-timeline {
  height: 13rem;
}

.rodliftanalysis-body ::-webkit-scrollbar-track {
  border-radius: 1.5rem;
}

.rodliftanalysis-scroll-area {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.rodliftanalysis-scroll-area>.analysis-result-item-container>div {
  margin-right: 1px;
}

.card-viewer {
  display: flex;
  height: 1016px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-grey-50, #e7ecee);
  background: var(--shades-white, #fff);
  box-shadow:
    0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  background-image: linear-gradient(#1e2b3a 0%, 15%, #101c2e 100%) !important;
}

.card-viewer-header {
  display: flex;
  padding: 1.25rem 1.5rem 0rem 1.5rem;
  align-items: flex-start;
  gap: 1rem;
  flex: 1 0 0;
  border-bottom: 1px solid #d0d8dd;
  width: 100%;
}

.card-viewer-card-title {
  flex: 1 0 0;
  color: #191f23;
  font-family: Mulish;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: var(--font-weight-bolder);
  line-height: 1.75rem;
}

.card-viewer-body {
  display: flex;
  padding: 2px 24px 20px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.1875rem;
  flex: 1 0 0;
  width: 100%;
}

.card-viewer-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.card-viewer-date-list {
  height: 33.25rem;
  flex-shrink: 0;
  width: 35%;
  overflow-y: hidden;
  scrollbar-width: none;
  border-radius: 4px;
  border: 1px solid var(--neutral-grey-50, #e7ecee);
}

.card-viewer-date-list::-webkit-scrollbar {
  display: none;
}

.card-viewer-card {
  align-items: flex-start;
  width: 100%;
}

.card-viewer-chart {
  width: 65%;
  height: 33.25rem;
  flex-shrink: 0;
}

.card-viewer-timeline {
  width: 65%;
  flex-shrink: 0;
}

.card-viewer-button-container {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  justify-content: flex-end;
}

.card-viewer-button {
  display: flex;
  padding: 0.25rem;
  padding-right: 0.1rem;
  align-items: center;
  gap: 0.25rem;
  background: none;
  border: none;
}

.card-viewer-button:hover {
  cursor: pointer;
}

.card-viewer-data {
  border-bottom: 1px solid #d0d8dd;
}

.card-viewer-calendar {
  display: flex;
  padding: 0.25rem;
  align-items: center;
  gap: 0.25rem;
  background: none;
  border: none;
  height: 20px;
}

.card-viewer-calendar:hover {
  cursor: pointer;
}

.loader-wrapper {
  width: 95%;
}

.card-calendar {
  border: solid 1px #b8c5cc;
  border-radius: 8px;
}

.card-calendar-width {}

.quick-select-time-container {
  padding-right: 2px;
}

.card-calendar-padding {
  padding-right: 4px;
}

.card-calendar-margin {
  margin-left: 10px;
}

.font-light {
  font-weight: var(--font-weight-bolder) !important;
}

.bg-blue-500 {
  background-color: #0094bd !important;
}

.text-blue-600 {
  color: #005f7c !important;
}

.card-viewer-date-picker-container {
  display: flex;
  align-items: center;
  gap: 0.1rem;
  width: 100%;
  justify-content: flex-end;
}

.date-picker-input {
  width: 100%;
  min-width: 200px;
  max-width: 230px;
}

.card-viewer-quick-date {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 0.25rem;
  padding: 0.125rem 0.25rem;
  height: 2rem;

  span {
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    color: #60bfda;
    cursor: pointer;
  }
}

.quick-date {
  display: flex;
  align-items: center;
  padding: 0.125rem 0.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

.quick-date-selected {
  padding: 0.125rem 0.25rem;
  border-radius: 0.25rem;
}

.full-screen {
  position: fixed;
  z-index: 10001;
  inset: 0px;
  height: 100vh;
  overflow-y: hidden;
  margin: auto !important;
}

.card-viewer-full-screen-override {
  height: 100% !important;
  overflow: auto;
}