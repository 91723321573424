.image-view-component .data-overlay-layer {
  position: absolute;
  transform-origin: top left;
}

.image-view-component .data-overlay {
  position: absolute;
  cursor: default;
  border: 1px solid silver;
  border-radius: 4px;
  box-shadow: 2px 2px 4px #ddd;
  background-color: rgb(255, 255, 255);
  overflow: auto;
}

.api-designation {
  position: relative;
  display: flex;
  margin-top: -60px;
  gap: 12px;
  cursor: default;
}

.asset-status-data-overlay {
  padding: 12px;
  gap: 12px;
}

.image-view-component .asset-status-data-overlay .label,
.image-view-component .asset-status-data-overlay .value {
  display: table-cell;
  padding: 0px !important;
  white-space: nowrap;
}

.data-overlay-title {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
}

.image-view-component .data-overlay .data-value {
  display: flex;
  justify-content: space-between;
}

.image-view-component .data-overlay .data-value .label {
  display: table-cell;
  padding: 8px 8px 8px 8px;
  white-space: nowrap;
}
.image-view-component .data-overlay .data-value .value {
  display: table-cell;
  padding: 8px 8px 8px 0px;
  white-space: nowrap;
}

.image-view-component .data-overlay .data-value .value {
  text-align: right;
  color: #60bfda;
  font-weight: 500;
}

/* Data overlay 'states' */
.image-view-component .data-overlay:has(.data-value.emphasis) {
  border-color: #60bfda;
}

.image-view-component .data-overlay .data-value.emphasis .label,
.image-view-component .data-overlay .data-value.emphasis .value {
  color: #60bfda;
  background-color: #dcf1f7;
  font-weight: bold;
}

.image-view-component .data-overlay:has(.data-value.alarm) {
  border-color: #dc4405;
}

.image-view-component .data-overlay.alarm .data-value .label,
.image-view-component .data-overlay.alarm .data-value .value {
  color: #dc4405;
  background-color: #fef5f5;
  font-weight: bold;
}

.image-view-component .data-overlay:has(.data-value.good) {
  border-color: #32d583;
  padding: 2px 4px;
}
.image-view-component .data-overlay .data-value.good .label,
.image-view-component .data-overlay .data-value.good .value {
  color: #32d583;
  background-color: #ecfdf3;
  font-weight: var(--font-weight-bold);
  padding: 0px 4px;
}

.image-view-component .data-overlay:has(.data-value.error) {
  border-color: #f97066;
  padding: 2px 4px;
}

.image-view-component .data-overlay .data-value.error .label,
.image-view-component .data-overlay .data-value.error .value {
  color: #f97066;
  background-color: #fef5f5;
  font-weight: var(--font-weight-bold);
  padding: 0px 4px;
}

.image-view-component .data-overlay:has(.data-value.warning) {
  border-color: #fdb022;
  padding: 2px 4px;
}

.image-view-component .data-overlay .data-value.warning .label,
.image-view-component .data-overlay .data-value.warning .value {
  color: #fdb022;
  background-color: #fef5f5;
  font-weight: var(--font-weight-bold);
  padding: 0px 4px;
}

.image-view-component .data-overlay:has(.data-value.normal) {
  padding: 2px 4px;
}

.image-view-component .data-overlay .data-value.normal .label,
.image-view-component .data-overlay .data-value.normal .value {
  background-color: #fef5f5;
  padding: 0px 4px;
}

.status-indicator {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 2px;
  margin-left: 2px;
}

.status-indicator-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.status-indicator-facility {
  margin-bottom: 0px;
  margin-right: 6px;
  margin-left: 0px;
}

.status-indicator.good {
  background-color: #32d583;
}

.status-indicator.error {
  background-color: #f97066;
}

.status-indicator.warning {
  background-color: #fdb022;
}

.status-indicator.normal {
  background-color: #4a5463;
}
