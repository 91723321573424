.notification-wrapper {
  height: 100%;
  overflow: auto;

  .comment-box {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 8px;
    width: 100%;
    border: 1px solid #d0d8dd;
    border-radius: 4px;
    margin-bottom: 8px;
    gap: 12px;

    a {
      color: #00789e;
    }

    h5,
    h4 {
      margin: 0px;
    }

    .notification-circle {
      position: absolute;
      right: 8px;
    }

    .node-text {
      color: #0d1011;
      font-size: 16px;
      font-style: normal;
      font-weight: var(--font-weight-bolder);
      line-height: 19px;
    }

    .date-text {
      font-size: 12px;
      line-height: 14px;
      font-weight: var(--font-weight-medium);
      color: #4b5e68;
    }

    .notification-header {
      display: flex;
      flex-direction: column;
    }

    .notification-footer {
      color: #4b5e68;
      font-size: 10px;
      font-weight: var(--font-weight-medium);
      line-height: 12px;
      letter-spacing: 0.2px;
    }

    .comment-text {
      font-size: 14px;
      font-weight: var(--font-weight-medium);
      line-height: 17px;
    }

    .comment-text.blue {
      color: #00789e;
    }

    .comment-container {
      display: flex;
      flex-direction: column;
      gap: 7px;
    }

    .comment-body-container {
      display: flex;
      padding: 4px 8px;
      align-items: center;
      border-radius: 2px;
      border: 0.5px solid #d0d8dd;
      background: #f7f9f9;
      width: 100%;
      overflow: hidden;

      .comment-body {
        color: #191f23;
        font-size: 12px;
        font-weight: var(--font-weight-medium);
        line-height: 14px;
        padding-top: 8px;
        padding-bottom: 8px;
        overflow-wrap: break-word;
        word-break: break-word;
      }
    }
  }

  .notify-buttons {
    margin-top: 10px;

    a {
      margin-left: 0px;
      margin-right: 8px;
      padding: 4px 8px;
      border-radius: 4px;
    }
  }

  .notify-buttons.red {
    a {
      border: 1px solid #dc4405;
      color: #dc4405;
      background: transparent;
    }
  }

  .notify-buttons.blue {
    a {
      border: 1px solid #0094bd;
      color: #0094bd;
      background: #f4fcff;
    }
  }

  .notification-with-buttons {
    height: auto;

    p {
      background: transparent;
      border: 0;
      padding: 0;
      margin: 6px 0px;
    }
  }
}

.theme-dark {
  .node-text {
    padding-bottom: 5px;
  }

  .date-text,
  .notification-footer {
    color: #b8c5cc !important;
  }

}