.modal {
  width: 384px;
  height: 255.31px;
}

.icon {
  margin-left: 165px;
  margin-bottom: 15px;
  width: 56px;
  height: 56px;
}
.partition {
  border: none;
  height: 1px;
  background-color: #4a5463; /* Color of the line */
  margin: 20px 0; /* Margin around the line */
}
.modal-btns {
  margin-top: 2.5rem;
}

.modal-text {
  color: #b8c5cc;
}

.modal-start-well {
  background-color: #00789e;
}
.modal-start-well:hover {
  background-color: #005f7c;
}

.modal-shutdown-well {
  background-color: #d92d20;
}

.modal-shutdown-well:hover {
  background-color: #991a0f;
}

.modal-clear-alarms {
  background-color: #12b76a;
}
.modal-clear-alarms:hover {
  background-color: #32d583;
}

.modal-reset-clock {
  background-color: #00789e;
}
.modal-reset-clock:hover {
  background-color: #005f7c;
}

.modal-bg {
  background: #131f2e;
  border: 1px solid #4a5463;
}

.btn-cancel {
  color: #f7f9f9;
  border: 1px solid #4a5463;
  background: #131f2e;
}
.btn-cancel:hover {
  background: #253040;
}
