.trend-library-dropdown-label-container {
  padding: 0.8rem;
  border-bottom: 1px solid var(--neutral-grey-50, #e7ecee);
}

.trend-library-dropdown-sub-header {
  padding: 0.8rem;
  color: var(--Neutral-Grey-50, #4b5e68);
  border-bottom: 1px solid var(--neutral-grey-50, #e7ecee);
}

.trend-library-content-search {
  flex: 1 1;
  position: relative;
  display: flex;
  padding: 10px;
}

.trend-library-option-label {
  font-weight: var(--font-weight-bolder);
  line-height: 20px;
}

.trend-library-search-icon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.trend-dropdown-header {
  padding: 0.8rem;
  border-bottom: 1px solid var(--neutral-grey-50, #e7ecee);
}

.trend-library-frame-item-content {
  width: 100%;
  height: 24px;
  gap: 12px;
  display: flex;
}

.trend-library-selected-item-text {
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  line-height: 24px;
  max-height: 153px;
  letter-spacing: 0em;
  text-align: left;
  overflow-y: auto;
  overflow-x: auto;
  border-bottom: 1px solid var(--neutral-grey-50, #e7ecee);
}

.trend-library-frame-item-carrot {
  width: 20px;
  height: 20px;
  padding: 0px 5px 0px 5px;
}

.trend-library-content-frame-item {
  width: 100%;
  min-width: 250px;
  height: 40px;
  padding: 8px 12px 8px 12px;
  border-radius: 6px;
  gap: 8px;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.trend-library-content-frame-item {
  width: 100%;
  min-width: 250px;
  height: 40px;
  padding: 8px 12px 8px 12px;
  border-radius: 6px;
  gap: 8px;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.trend-library-content-frame-item:hover {
  background: #dcf1f7;
}

.trend-library-content-frame-item-text {
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #323f46;
}

.trend-library-selected-trend-item {
  display: flex;
  padding: 6px 16px;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
}

.trend-library-selected-trend-item-name {
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  line-height: 24px;
}

.trend-library-item-details {
  display: flex;
  height: 220px;
  flex-direction: column;
  width: 384px;
  gap: 4px;
  overflow-y: auto;
  overflow-x: auto;
}

.item-selected {
  background-color: #f7f9f9;
}

.trend-dropdown-container {
  position: absolute;
  right: 0;
  z-index: 10;
  gap: 1px;
  border-radius: 8px;
  border: var(--Number, 1px) solid var(--Neutral-Grey50, #e7ecee);
  background: var(--Shades-White, #fff);
  box-shadow:
    0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
}

.theme-dark {

  .trend-library-dropdown-label-container,
  .trend-library-selected-item-text {
    border-color: #4a5463;

  }

  .trend-library-item-details {
    .checkbox-parent .checkbox {
      border-color: #788497;
      background: #444f5e;
    }
  }
}