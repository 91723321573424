.navigation-icons {
  width: 24px;
  margin-right: 9px;
}

.navigation-icon g,
.navigation-icon polyline,
.navigation-icon line,
.navigation-icon path,
.navigation-icon rect,
.navigation-icon polygon {
  stroke: #f7f9f9 !important;
}

.navigation-icon-active g,
.navigation-icon-active polyline,
.navigation-icon-active line,
.navigation-icon-active path,
.navigation-icon-active rect,
.navigation-icon-active polygon {
  stroke: #60bfda !important;
}
