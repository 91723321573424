.toolbar-eye-btn {
  display: flex;
  padding: 10px;
  gap: 4px;
  border: none;
  background: white;
  cursor: pointer;
  position: relative;
  width: 45px;
}
.download-btn {
  display: flex;
  padding: 8px;
  gap: 4px;
  border: none;
  cursor: pointer;
  position: relative;
  border-radius: 8px;
}
.chevron-icon {
  position: absolute; /* Position the centered items absolutely */
  top: 18px; /* Center vertically */
  left: 85%; /* Center horizontally */
}

.download-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ui-settings-button {
  display: flex;
  align-items: center;
  gap: 4px;
}
