.breadcrumb {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 8px;
  padding-left: 32px;
}

.group {
  display: flex;
  gap: 8px;
  line-height: 28px;
}

.group-btn {
  color: #00789e;
  cursor: pointer;
  display: flex;
  transform: rotate(-0.11deg);
  font-size: 23px;
  font-weight: var(--font-weight-bold);
}

.group-btn:hover {
  color: #005f7c;
}

.breadcrumb-separator {
  font-size: 23px;
  font-weight: var(--font-weight-bold);
}

.assets {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.assets-ddn {
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-grey-100, #d0d8dd);
  background: var(--shades-white, #fff);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.assets-ddn-option {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid var(--neutral-grey-50, #e7ecee);
  background: var(--shades-white, #fff);

  /* Shadow/lg */
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}
