.login-btn {
  width: 408px;
  height: 36px;
  padding: 8px 14px 8px 14px;
  gap: 8px;
  border-radius: 8px;
  border: 1px;
  background: #005f7c;
  color: #ffffff;
  margin-top: 20px;
}

.login-container {
  width: 408px;
  height: 325.79px;
  gap: 32px;

  .input-field {
    border-color: #4A5463;
  }
}

.login-title {
  //styleName: Heading/H2/Bold;
  font-family: Mulish;
  font-size: 39px;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: -0.04em;
  text-align: left;
  padding-bottom: 10px;
}

.login-sub-title {
  //styleName: Paragraph/P2/Regular;
  font-family: Mulish;
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #4b5e68;
  padding-bottom: 20px;
}

.onboarding-shell-image.login {
  height: 100%;
  padding: 0;

  .login-image {
    background: url('../../../images/login-background.jpg') no-repeat 0 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    text-indent: -9999px;
  }
}

.error-text {
  color: red;
  margin-bottom: 15px;
}